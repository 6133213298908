import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Mantén este archivo si necesitas estilos globales
import GestionForm from './GestionForm'; // Importa tu componente GestionForm
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('app')); // Asegúrate de que el contenedor sea correcto
root.render(
  <React.StrictMode>
    <GestionForm /> {/* Renderiza el componente GestionForm */}
  </React.StrictMode>
);

// Si quieres comenzar a medir el rendimiento de tu aplicación, pasa una función
// para registrar resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un endpoint de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();

