// GestionForm.js
import React, { useState } from 'react';
import './GestionForm.css'; // Asegúrate de que los estilos estén en este archivo CSS
import { db } from './firebaseConfig'; // Importa la instancia de Firestore
import { collection, addDoc } from "firebase/firestore"; // Importa las funciones de Firestore

function GestionForm() {
  const [formData, setFormData] = useState({
    nombre: '',
    fecha_nacimiento: '',
    discapacidad: '',
    telefono: '',
    colonia: '',
    calle: '',
    codigo_postal: '',
    entre_calles: '',
    solicitud: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Agregar los datos del formulario a una colección llamada "gestiones" en Firestore
      await addDoc(collection(db, "Solicitudes"), {
        nombre: formData.nombre,
        fecha_nacimiento: formData.fecha_nacimiento,
        discapacidad: formData.discapacidad,
        telefono: formData.telefono,
        colonia: formData.colonia,
        calle: formData.calle,
        codigo_postal: formData.codigo_postal,
        entre_calles: formData.entre_calles || 'N/A', // Si no se llenan las calles, se pone 'N/A'
        solicitud: formData.solicitud,
        fecha_solicitud: new Date() // Guarda la fecha en que se realizó la solicitud
      });

      console.log('Gestión guardada exitosamente');
      // Opcional: aquí podrías limpiar el formulario después de enviar los datos
      setFormData({
        nombre: '',
        fecha_nacimiento: '',
        discapacidad: '',
        telefono: '',
        colonia: '',
        calle: '',
        codigo_postal: '',
        entre_calles: '',
        solicitud: ''
      });

      alert('Solicitud enviada con éxito');
    } catch (error) {
      console.error('Error al guardar la solicitud: ', error);
      alert('Hubo un error al enviar la solicitud, por favor intenta de nuevo.');
    }
  };

  return (
    <div className="container">
      <h1>Bienvenido a Solicitudes</h1>
      <h2>Por favor escribe los datos para solicitar una gestión</h2>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-column">
            <label htmlFor="nombre">Nombre completo</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              placeholder="Nombre completo"
              required
            />

            <label htmlFor="fecha_nacimiento">Fecha de nacimiento</label>
            <input
              type="date"
              id="fecha_nacimiento"
              name="fecha_nacimiento"
              value={formData.fecha_nacimiento}
              onChange={handleChange}
              required
            />

            <label htmlFor="discapacidad">Discapacidad</label>
            <select
              id="discapacidad"
              name="discapacidad"
              value={formData.discapacidad}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Selecciona una opción
              </option>
              <option value="Ninguna">Ninguna</option>
              <option value="fisica">Física</option>
              <option value="sensorial">Sensorial</option>
              <option value="cognitiva">Cognitiva</option>
            </select>

            <label htmlFor="telefono">Número telefónico</label>
            <input
              type="tel"
              id="telefono"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              placeholder="Número telefónico"
              required
            />
          </div>

          <div className="form-column">
            <label htmlFor="colonia">Colonia</label>
            <input
              type="text"
              id="colonia"
              name="colonia"
              value={formData.colonia}
              onChange={handleChange}
              placeholder="Colonia"
              required
            />

            <label htmlFor="calle">Calle y Numero</label>
            <input
              type="text"
              id="calle"
              name="calle"
              value={formData.calle}
              onChange={handleChange}
              placeholder="Calle"
              required
            />

            <label htmlFor="codigo_postal">Código postal</label>
            <input
              type="text"
              id="codigo_postal"
              name="codigo_postal"
              value={formData.codigo_postal}
              onChange={handleChange}
              placeholder="Código postal"
              required
            />

            <label htmlFor="entre_calles">Entre calles</label>
            <input
              type="text"
              id="entre_calles"
              name="entre_calles"
              value={formData.entre_calles}
              onChange={handleChange}
              placeholder="Entre calles"
            />
          </div>
        </div>

        <label htmlFor="solicitud">Solicitud</label>
        <textarea
          id="solicitud"
          name="solicitud"
          value={formData.solicitud}
          onChange={handleChange}
          placeholder="Descripción de la solicitud"
          required
        />

        <button type="submit" className="submit-btn">
          Enviar
        </button>
      </form>

      <div className="footer">
        <h3>De tu amigo Pepe Shanghai</h3>
        <p>
          Este sitio web está diseñado para que los habitantes de la ciudad de
          Xalisco puedan expresar y gestionar sus necesidades comunitarias.
        </p>
      </div>
    </div>
  );
}

export default GestionForm;

