// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Configuración de Firebase (obtén esta información desde la consola de Firebase)
const firebaseConfig = {
    apiKey: "AIzaSyCNRauZ_1tMLyCSWxbHnaBWWh86m9k1Ypk",
    authDomain: "gestion-x-646d5.firebaseapp.com",
    databaseURL: "https://gestion-x-646d5-default-rtdb.firebaseio.com",
    projectId: "gestion-x-646d5",
    storageBucket: "gestion-x-646d5.appspot.com",
    messagingSenderId: "765928343644",
    appId: "1:765928343644:web:3a8621478a518fb6993e99",
    measurementId: "G-L2D3SV8XVH"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const db = getFirestore(app);

export { db };
